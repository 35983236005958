// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

/* eslint-disable react/require-render-return */

import { Component } from '../component';
import render from '../render';

export class Form extends Component {
    _initConfiguration(config = {}) {
        super._initConfiguration({
            tag: 'form',
            ...config,
            attrs: {
                enctype: 'application/x-www-form-urlencoded',
                method: 'post',
                ...config.attrs,
            },
        });
    }

    _addEvents() {
        this._componentElement._formSubmit = this._componentElement.submit;
        this._componentElement.submit = this._onSubmit.bind(this);
        this._componentElement.addEventListener('submit', event => {
            this._onSubmit();
            event.preventDefault();
        });
    }

    _onSubmit() {
        this._componentElement._formSubmit();
    }

    render() {
        super.render();

        const params = this._getConfigParam('params', {});
        if (document.getElementById('forgery_protection_token')) {
            params.forgery_protection_token = document.getElementById('forgery_protection_token').content; // eslint-disable-line camelcase
        }

        Object.keys(params).forEach(name => {
            render(this._componentElement, (
                new Element('input', {
                    type: 'hidden',
                    value: params[name],
                    name,
                })
            ));
        });
    }
}
