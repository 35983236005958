// Copyright 1999-2018. Plesk International GmbH. All rights reserved.

import { createElement, Component, StrictMode } from 'react';

import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import Switch from './components/Switch';
import Route from './components/RouteRequire';
import ConfigContext from './components/ConfigContext';
import routes from './routes';

class App extends Component {
    getConfirmation = (message, callback) => {
        try {
            const { callback: callbackReturnValue } = JSON.parse(message);

            if (typeof callbackReturnValue !== 'undefined') {
                callback(callbackReturnValue);
            } else {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-alert
            callback(window.confirm(message));
        }
    };

    render() {
        return (
            <StrictMode>
                <ConfigContext.Provider value={this.props.config}>
                    <BrowserRouter getUserConfirmation={this.getConfirmation}>
                        <Switch>
                            {routes.map(({ path, ...props }) => (
                                <Route key={path} path={path} {...props} />
                            ))}
                        </Switch>
                    </BrowserRouter>
                </ConfigContext.Provider>
            </StrictMode>
        );
    }
}

App.propTypes = {
    config: PropTypes.object.isRequired,
};

export default App;
