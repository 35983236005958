// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

/* eslint-disable react/require-render-return */

import { Component, getComponent } from './component';
import { Container } from './container';
import { BrowserFeatures } from './browser-features';
import { Popup } from './popup';
import { redirect } from './form-redirect';
import { Observer } from './observer';
import { Locale } from './locale';
import createComponent from './createComponent';
import prepareUrl from './prepareUrl';
import render from './render';
import ce from './createElement';
import escapeHtml from './escapeHtml';
import api from './api';

const STATUS_DONE = 'done';
const STATUS_ERROR = 'error';
const STATUS_STARTED = 'started';
const STATUS_NOT_STARTED = 'not_started';
const STATUS_PREPARING = 'preparing';
const STATUS_FLYING = 'flying';
const STATUS_CANCELED = 'canceled';

export class ProgressBar extends Container {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._contentAreaId = `${this._id}-content-area`;
        this._preparingItems = [];
        this._preparingCounter = 0;

        this.pe = null;
        this.pleskWS = null;
        if (this._getConfigParam('wsEnabled')) {
            this.pleskWS = Jsw.pleskWS.bind({
                actions: {
                    // eslint-disable-next-line camelcase
                    task_created: this.onUpdated.bind(this),
                    // eslint-disable-next-line camelcase
                    task_updated: this.onUpdated.bind(this),
                    // eslint-disable-next-line camelcase
                    task_deleted: this.onDeleted.bind(this),
                },
                onOpen: this.loadTasks.bind(this),
                onClose: this.loadTasks.bind(this),
            });
        } else {
            this.loadTasks();
        }
    }

    _initComponentElement() {
        super._initComponentElement();

        const isAsyncProgressBarCollapsed = window.localStorage.getItem('isAsyncProgressBarCollapsed');

        this._updateComponentElement(
            `<div id="asyncProgressBar" class="async-progress-bar ${'true' === isAsyncProgressBarCollapsed ? 'async-progress-bar-collapsed' : ''} js-scrollbar-spacer"> ` +
                '<div class="async-progress-bar-wrap">' +
                    '<div id="asyncProgressBarTop" class="async-progress-bar-top">' +
                        '<div class="async-progress-bar-control">' +
                            `<a id="asyncProgressBarControlHide" href="#" class="async-progress-bar-control-hide">${this.lmsg('progressBarHide')}</a>` +
                            `<a id="asyncProgressBarControlShow" href="#" class="async-progress-bar-control-show">${this.lmsg('progressBarShow')}</a>` +
                        '</div>' +
                        '<div class="async-progress-bar-title">' +
                            '<span id="asyncProgressBarTitleTasks" class="async-progress-bar-title-tasks hidden"></span>' +
                            '<span id="asyncProgressBarTitleTasksError" class="async-progress-bar-title-tasks-error hidden"></span>' +
                            '<span id="asyncProgressBarTitleTasksComplete" class="async-progress-bar-title-tasks-complete hidden"></span>' +
                            '<span id="asyncProgressBarTitleTasksWarning" class="async-progress-bar-title-tasks-warning hidden"></span>' +
                            `<a id="asyncProgressBarHideCompletedTasks" class="async-progress-bar-title-tasks-hide hidden" href="#">${this.lmsg('hideCompletedTasks')}</a>` +
                        '</div>' +
                    '</div>' +
                    '<div class="async-progress-bar-body">' +
                        `<ul class="async-progress-bar-list" id="${this._contentAreaId}">` +
                        '</ul>' +
                    '</div>' +
                '</div>' +
            '</div>'
        );
    }

    addPreparingItem(title) {
        const id = `preparing-${this._preparingCounter}`;
        const item = new ProgressBar.Item({
            errors: [],
            progressTitle: title,
            status: 'flying',
            id,
        });
        this._preparingItems.unshift(item);
        this._preparingCounter++;
        this._items.unshift(item);
        return id;
    }

    // do not remove due to backward compatibility
    removePreparingItem() {
    }

    toggle() {
        const element = document.getElementById('asyncProgressBar');
        element.classList.toggle('async-progress-bar-collapsed');

        const isAsyncProgressBarCollapsed = element.classList.contains('async-progress-bar-collapsed') ? 'true' : 'false';
        window.localStorage.setItem('isAsyncProgressBarCollapsed', isAsyncProgressBarCollapsed);

        this._updateTitle();
    }

    loadTasks() {
        api.get(this._getConfigParam('progressUrl'))
            .then(data => {
                if (!data.items) {
                    return;
                }
                data.items.forEach(newItemData => this.onItemStatusChange(newItemData));
                this.getItems().forEach(item => {
                    if (!data.items.some(newItemData => item.getId() === newItemData.id)) {
                        this.onItemStatusChange({ ...item.initialConfig, status: STATUS_DONE });
                    }
                });
                this.setItems(this._preparingItems.concat(data.items));
            });
    }

    // do not remove due to backward compatibility
    update() {
        if (this.pleskWS && this.pleskWS.isReady()) {
            return;
        }

        this.removePreparingItems();
        this.loadTasks();
    }

    onUpdated(item) {
        const items = this.getItems();
        for (let j = 0; j < items.length; j++) {
            if (items[j].getId() === item.id) {
                if (item.status !== items[j]._status) {
                    this.onItemStatusChange(item);
                }

                item.isRefreshLinkEnabled = STATUS_STARTED !== item.status && items[j].isStarted() ? true : items[j]._isRefreshLinkEnabled;
                items[j] = item;
                this.setItems(items);
                return;
            }
        }

        this.removePreparingItems();

        this.onItemStatusChange(item);
        items.unshift(item);
        this.setItems(items);
    }

    onDeleted(item) {
        this.onItemStatusChange({ ...item, status: STATUS_DONE });
        this.removeItemsByIds([item.id]);
    }

    removePreparingItems() {
        if (this._preparingItems.length) {
            this.removeItemsByIds(this._preparingItems.map(item => item.getId()));
            this._preparingItems = [];
        }
    }

    removeItemsByIds(ids) {
        if (!ids.length) {
            return;
        }
        const items = this.getItems();
        for (let i = 0; i < ids.length; i++) {
            for (let j = 0; j < items.length; j++) {
                if (items[j].getId() === ids[i]) {
                    const renderTarget = items[j].getRenderTarget();
                    renderTarget.parentNode.removeChild(renderTarget);
                    items.splice(j, 1);
                    break;
                }
            }
        }
        this.setItems(items);
    }

    onItemStatusChange(newItemData) {
        Observer.notify(newItemData, 'plesk:taskUpdate');
        const isComplete = status => [STATUS_DONE, STATUS_ERROR, STATUS_CANCELED].indexOf(status) !== -1;
        if (isComplete(newItemData.status)) {
            Observer.notify(newItemData, 'plesk:taskComplete');
        }
    }

    setItems(items) {
        this._initItems(items);
        this._itemsReady = true;
        this._renderItems();
        this._updateProgressDialog();
    }

    removeCompletedTask() {
        const ids = this._items
            .filter(item => !item.isStarted())
            .map(item => item.getId());

        if (ids.length > 0) {
            api.post(this._getConfigParam('removeUrl'), { ids })
                .then(() => {
                    this.removeItemsByIds(ids);
                });
        }
    }

    fly(beginOffset, taskName, action) {
        if (!BrowserFeatures.transition) {
            action();
            return null;
        }

        const item = document.createElement('div');
        item.className = 'async-progress-bar-floating-item';
        item.innerHTML = taskName;
        item.style.top = `${beginOffset.top}px`;
        item.style.left = `${beginOffset.left}px`;
        document.body.appendChild(item);
        const progressBarComponent = this;
        const progressBarItemId = progressBarComponent.addPreparingItem(taskName);
        progressBarComponent._renderItems();
        const progressBarPreparingItem = document.querySelector('.async-progress-bar-preparing-item-begin');
        if (document.getElementById('asyncProgressBar').classList.contains('async-progress-bar-collapsed')) {
            this.toggle();
        }
        setTimeout(() => {
            const progressBarTotalHeight = Element.getHeight(progressBarPreparingItem);
            progressBarPreparingItem.classList.add('async-progress-bar-preparing-item');
            setTimeout(() => {
                progressBarPreparingItem.classList.add('async-progress-bar-preparing-item-end');
                const endOffset = Element.cumulativeOffset(document.querySelector('ul.async-progress-bar-list'));
                const endScrollOffset = Element.cumulativeScrollOffset(document.querySelector('ul.async-progress-bar-list'));
                const progressBar = progressBarPreparingItem.firstElementChild;
                const height = Element.measure(progressBar, 'height');
                const width = Element.measure(progressBar, 'width');
                item.style.top = `${endOffset.top + endScrollOffset.top - progressBarTotalHeight}px`;
                item.style.left = `${endOffset.left}px`;
                item.style.height = `${height}px`;
                item.style.width = `${width}px`;
                item.classList.add('async-progress-bar-floating-item-end');

                const transitionEvents = ['webkitTransitionEnd', 'transitionend', 'msTransitionEnd', 'oTransitionEnd'];

                const handleTransitionEnd = () => {
                    transitionEvents.forEach(eventName => {
                        item.removeEventListener(eventName, handleTransitionEnd);
                    });
                    item.parentNode.removeChild(item);
                    const progressBarItem = getComponent(progressBarItemId);
                    progressBarItem.setStatus(STATUS_PREPARING);
                    const progressBar = progressBarPreparingItem.firstElementChild;
                    progressBar.style.visibility = 'visible';
                    action();
                };

                transitionEvents.forEach(eventName => {
                    item.addEventListener(eventName, handleTransitionEnd);
                });
            }, 0);
        }, 0);

        return progressBarItemId;
    }

    progressDialog(task, { onHide, ...params } = {}) {
        const returnUrl = task && task.returnUrl;
        this._progressBarItem = task instanceof ProgressBar.Item ? task : createComponent(task);
        this.isOpenProgressDialog = true;

        if (!this._progressDialog) {
            this._progressDialog = document.createElement('div');
        }

        this.renderProgressDialog = () => {
            Plesk.require('app/progress-dialog', run => {
                if (!this._progressBarItem || !this._progressDialog) {
                    return;
                }

                run({
                    container: this._progressDialog,
                    isOpen: this.isOpenProgressDialog,
                    title: this._progressBarItem.getProgressTitle(),
                    steps: this._progressBarItem.getSteps(),
                    errors: this._progressBarItem.getVisibleErrors(),
                    onHide: () => {
                        this.isOpenProgressDialog = false;
                        this.renderProgressDialog();

                        if (onHide) {
                            onHide();
                            return;
                        }

                        const redirectUrl = this._progressBarItem.isComplete()
                            ? this._progressBarItem._getConfigParam('redirect') || returnUrl
                            : returnUrl;
                        const doRedirect = () => {
                            if (redirectUrl) {
                                redirect(redirectUrl);
                            } else {
                                this.show();
                            }
                        };

                        if (this._progressBarItem.isCompleteSuccessfully()) {
                            this._progressBarItem.remove().then(doRedirect);
                        } else {
                            doRedirect();
                        }
                    },
                    locale: this.getLocale().messages,
                    ...params,
                });
            });
        };

        this.renderProgressDialog();
        this.update();
        this.hide();
    }

    _updateProgressDialog() {
        if (this._progressBarItem) {
            this._progressBarItem = this.getItem(this._progressBarItem.getId());
            this.renderProgressDialog();
        }
    }

    _renderItems() {
        super._renderItems();

        this._updateTitle();
        if (this._items.length) {
            this._componentElement.classList.remove('hidden');
            this.setPeriodicalExecutor();
        } else {
            this._componentElement.classList.add('hidden');
        }
    }

    setPeriodicalExecutor() {
        if (!this.pe && this.hasStartedTasks()) {
            this.pe = new PeriodicalExecuter(() => {
                this.update();
                if (!this.hasStartedTasks()) {
                    this.pe.stop();
                    this.pe = null;
                }
            }, 5);
        }
    }

    hasStartedTasks() {
        return this._items.some(item => item.isStarted());
    }

    _updateTitle() {
        let countRunning = 0;
        let countCompleteError = 0;
        let countCompleteSuccessfully = 0;
        let countCompleteWarning = 0;

        this._items.forEach(item => {
            if (item.isCompleteSuccessfully()) {
                countCompleteSuccessfully++;
            } else if (item.isCompleteWithWarning()) {
                countCompleteWarning++;
            } else if (item.isCompleteWithError()) {
                countCompleteError++;
            } else {
                countRunning++;
            }
        });

        const taskRunningElement = document.getElementById('asyncProgressBarTitleTasks');
        if (countRunning > 0) {
            if (countRunning === this._items.length) {
                taskRunningElement.innerHTML = this.lmsg('taskInProgress', { count: countRunning });
            } else {
                taskRunningElement.innerHTML = countRunning;
            }
            document.getElementById('asyncProgressBar').classList.remove('async-progress-bar-complete');
            taskRunningElement.classList.remove('hidden');
        } else {
            document.getElementById('asyncProgressBar').classList.add('async-progress-bar-complete');
            taskRunningElement.classList.add('hidden');
        }
        if (countCompleteSuccessfully > 0 || countCompleteWarning > 0 || countCompleteError > 0) {
            document.getElementById('asyncProgressBarHideCompletedTasks').classList.remove('hidden');
        } else {
            document.getElementById('asyncProgressBarHideCompletedTasks').classList.add('hidden');
        }

        this._updateTaskTitleElement('asyncProgressBarTitleTasksError', countCompleteError);
        this._updateTaskTitleElement('asyncProgressBarTitleTasksWarning', countCompleteWarning);
        this._updateTaskTitleElement('asyncProgressBarTitleTasksComplete',
            document.getElementById('asyncProgressBar').classList.contains('async-progress-bar-collapsed') && countCompleteSuccessfully === this._items.length
                ? this.lmsg('allTasksCompleted', { num: countCompleteSuccessfully })
                : countCompleteSuccessfully
        );
    }

    _updateTaskTitleElement(id, title) {
        if (!this._itemsReady) {
            return;
        }
        const element = document.getElementById(id);

        if (element._oldValue !== undefined && title > element._oldValue) {
            element.innerHTML = `<span>${title}</span>`;
        } else {
            element.innerHTML = title;
        }
        element.classList[title === 0 ? 'add' : 'remove']('hidden');
        element._oldValue = title;
    }

    _renderItem(item) {
        const renderTargetId = `${this._id}-item-${item.getId()}`;
        if (!document.getElementById(renderTargetId)) {
            render(
                document.getElementById(this._contentAreaId),
                `<li id="${renderTargetId}" class="async-progress-bar-item"></li>`,
                'top'
            );
        }
        item.setLocale(this.getLocale());
        item.setProgressBarElement(this);
        render(document.getElementById(renderTargetId), item);
    }

    _addEvents() {
        super._addEvents();

        document.getElementById('asyncProgressBarTop').addEventListener('click', e => {
            e.preventDefault();
            this.toggle();
        });
        document.getElementById('asyncProgressBarHideCompletedTasks').addEventListener('click', e => {
            e.preventDefault();
            this.removeCompletedTask();
        });
    }
}

ProgressBar.Item = class Item extends Component {
    _initConfiguration(config) {
        super._initConfiguration(config);

        this._id = this._getConfigParam('id', '');
        this._status = this._getConfigParam('status', '');
        this._errors = this._getConfigParam('errors', []);
        this._output = this._getConfigParam('output', []);
        this._isRefreshLinkEnabled = this._getConfigParam('isRefreshLinkEnabled', false)
            && this._getConfigParam('isRefreshAllowed', true);
        this._progressValue = this._getConfigParam('progressValue', 0);
        this._canCancel = this._getConfigParam('canCancel', true);
        this._referrer = this._getConfigParam('referrer', '');
        this._progressBarElement = {};
    }

    getCloseButton() {
        return ce('a', {
            href: '#',
            onclick: event => {
                event.preventDefault();
                this.remove();
            },
        }, ce('span.close'));
    }

    getSteps() {
        return this._getConfigParam('steps', {});
    }

    setProgressBarElement(element) {
        this._progressBarElement = element;
    }

    getProgressBarElement() {
        return this._progressBarElement;
    }

    getProgressTitle() {
        return this._getConfigParam('progressTitleHtml', escapeHtml(this._getConfigParam('progressTitle', '')));
    }

    getProgressValue() {
        return this._progressValue;
    }

    getStatus() {
        return this._status;
    }

    setStatus(status) {
        this._status = status;
    }

    getProgressDialogLink() {
        if (!Object.keys(this.getSteps()).length) {
            return null;
        }
        return ce('.async-progress-bar-item-control', ce('a', {
            href: '#',
            onclick: event => {
                event.preventDefault();
                this.getProgressBarElement().progressDialog(this);
            },
        }, this.lmsg('progressDialogLink')));
    }

    getRefreshLink() {
        const _redirect = this._getConfigParam('redirect');
        if (_redirect) {
            return ce('', ce('a', {
                href: '#',
                onclick(event) {
                    event.preventDefault();
                    redirect(_redirect);
                },
            }, _redirect.title ? escapeHtml(_redirect.title) : this.lmsg('refresh')));
        }

        if (this._isRefreshLinkEnabled && window.location.pathname === this._referrer) {
            return ce('', ce('a', {
                href: '#',
                onclick(event) {
                    event.preventDefault();
                    redirect(prepareUrl(window.location.pathname));
                },
            }, this.lmsg('refresh')));
        }
        return '';
    }

    hasErrors() {
        return this._errors.length > 0;
    }

    getErrors() {
        return this._errors;
    }

    getVisibleErrors() {
        const hideErrors = this._getConfigParam('hideErrors', false);

        if (hideErrors || !this.hasErrors()) {
            return [];
        }

        return this.getErrors();
    }

    getErrorsString() {
        const errors = this.getVisibleErrors();

        if (errors.length === 0) {
            return null;
        }

        return ce('.error-details', ce('ul',
            errors.map(error => ce('li', escapeHtml(String(error))))
        ));
    }

    hasOutput() {
        return this._output.length > 0;
    }

    getOutputString() {
        return this._output.map(line => `<li>${escapeHtml(String(line))}</li>`).join('');
    }

    getCancelButton() {
        // PPP-13558
        return '';
        // if (!this._canCancel) {
        //     return '';
        // }
        // return ce('a', {
        //     href: '#',
        //     onclick: function (event) {
        //         event.preventDefault();
        //         this.remove();
        //     }.bind(this),
        // }, this.lmsg('cancel'));
    }

    isCompleteSuccessfully() {
        return STATUS_DONE === this._status && !this.hasErrors();
    }

    isComplete() {
        return [STATUS_DONE, STATUS_ERROR, STATUS_CANCELED].indexOf(this._status) !== -1;
    }

    isCompleteWithWarning() {
        return STATUS_DONE === this._status && this.hasErrors();
    }

    isStarted() {
        return STATUS_STARTED === this._status || STATUS_NOT_STARTED === this._status;
    }

    isPreparing() {
        return STATUS_PREPARING === this._status;
    }

    isFlying() {
        return STATUS_FLYING === this._status;
    }

    isProgressUndefined() {
        return this._progressValue === -1;
    }

    isCompleteWithError() {
        return STATUS_ERROR === this._status;
    }

    remove() {
        return api.post(this.getProgressBarElement()._getConfigParam('removeUrl'), { ids: [this.getId()] })
            .then(() => {
                this.getProgressBarElement().removeItemsByIds([this.getId()]);
            });
    }

    render() {
        let progressBar = [];
        const element = this.getRenderTarget();

        if (this.isCompleteSuccessfully()) {
            element.classList.add('async-progress-bar-item-complete');
            progressBar = ce('.async-progress-bar-item-msg', [
                this.getCloseButton(),
                ce('.async-progress-bar-item-msg-body', [
                    this.getProgressTitle(),
                    this.hasOutput() ? ce('.output-details', ce('ul', this.getOutputString())) : '',
                    this.getRefreshLink(),
                ]),
            ]);
        } else if (this.isCompleteWithWarning() || this.isCompleteWithError()) {
            element.classList.add(this.isCompleteWithWarning() ? 'async-progress-bar-item-warning' : 'async-progress-bar-item-error');
            progressBar = ce('.async-progress-bar-item-msg', [
                this.getCloseButton(),
                ce('.async-progress-bar-item-msg-body', [
                    this.getProgressTitle(),
                    this.getErrorsString(),
                    this.getRefreshLink(),
                ]),
            ]);
        } else if (this.isPreparing() || this.isFlying() || this.isProgressUndefined()) {
            if (!this.isProgressUndefined()) {
                element.classList.add('async-progress-bar-preparing-item-begin');
            }
            progressBar = [
                ce('.async-progress-bar-item-heading', { style: this.isFlying() ? 'visibility: hidden' : '' }, [
                    ce('.async-progress-bar-item-title', this.getProgressTitle()),
                ]),
                ce('.progress.progress-sm', ce('.progress-bar.progress-bar-striped.active', { style: 'width: 100%' })),
            ];
            const link = this.getProgressDialogLink();
            if (link) {
                progressBar.push(ce('.async-progress-bar-item-footer', [link, '&nbsp;']));
            }
        } else {
            progressBar = [
                ce('.async-progress-bar-item-heading', [
                    ce('.async-progress-bar-item-control', this.getCancelButton()),
                    ce('.async-progress-bar-item-title', this.getProgressTitle()),
                ]),
                ce('.progress.progress-sm', ce('.progress-bar', { style: `width: ${this.getProgressValue()}%` })),
                ce('.async-progress-bar-item-footer', [
                    this.getProgressDialogLink(),
                    this.lmsg('percentCompleted', { percent: this.getProgressValue() }),
                ]),
            ];
        }
        render(element, ce('.async-progress-bar-item-wrap', progressBar), 'inner');

        this._addEvents();
        this._addTooltips();
    }
};

ProgressBar.showOutputPopup = (event, link) => {
    event.preventDefault();

    const config = JSON.parse(link.dataset.config);
    const { output } = link.dataset;
    const locale = new Locale(config.locale);

    const popup = new Popup({
        title: locale.lmsg('popupTitle'),
        content: (
            '<div class="tree-box" style="height: 400px">' +
                `<p>${escapeHtml(output).replace(/\n/gm, '<br>')}</p>` +
            '</div>'
        ),
        buttons: [{
            title: locale.lmsg('popupClose'),
            handler() {
                popup.hide();
            },
        }],
    });
};
