// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { Form } from './form';
import { redirect } from '../form-redirect';

export class GetPassword extends Form {
    _initComponentElement() {
        super._initComponentElement();

        const content = (
            '<div class="login-info">' +
                `<h3>${this.lmsg('label')}</h3>${this.lmsg('text')}` +
            '</div>' +

            '<div id="getLinkSection-email-form-row" class="form-row">' +
                `<div class="field-name"><label for="getLinkSection-email">${this.lmsg('emailLabel')}&nbsp;</label></div>` +
                '<div class="field-value">' +
                    `<input type="text" name="email" id="getLinkSection-email" value="${this._getConfigParam('emailValue', '')}" class="input-text" />` +
                '</div>' +
            '</div>'
        );

        this._componentElement.innerHTML = (
            '<div id="getLinkSection" class="form-box">' +
                `<div id="getLinkSection-content-area">${this._getConfigParam('warnings', '')}${content}</div>` +
            '</div>' +

            '<div class="btns-box">' +
                '<div class="box-area">' +
                    '<div class="form-row">' +
                        '<div class="field-name">' +
                        '</div>' +
                        '<div class="field-value">' +
                            `<button id="btn-send" class="btn" type="submit" value="" name="send">${this.lmsg('send')}</button>` +
                            `<button id="btn-cancel" class="btn" type="button" value="" name="cancel">${this.lmsg('cancel')}</button>` +

                            `<input type="image" src="${require('images/blank.gif')}" style="border: 0; height: 0; width: 0; position: absolute;" />` +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>'
        );

        this._componentElement.querySelector('#btn-cancel').addEventListener('click', () => {
            redirect('/login_up.php');
        });
        this._componentElement.querySelector('#getLinkSection-email-form-row').focus();
    }
}
