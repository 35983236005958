// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import addStatusMessage from './addStatusMessage';
import clearStatusMessages from './clearStatusMessages';
import escapeHtml from './escapeHtml';

export default message => {
    clearStatusMessages();
    addStatusMessage('error', `Internal error ;-P<pre>${escapeHtml(message)}</pre>`);
};
