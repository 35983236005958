// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import './styles/index.less';

import './polyfill';

import * as Jsw from './jsw';
import * as Plesk from './plesk';
import * as Sentry from '@sentry/browser';
import './cp';
import './legacy';

window.Jsw = Jsw;
window.Plesk = Plesk;
window.Sentry = Sentry;
