// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { Container } from './container';
import { DropdownManager } from './dropdown-manager';
import { Tooltip } from './tooltip';
import render from './render';
import createElement from './createElement';
import escapeHtml from './escapeHtml';

export class ResponsiveDropdown extends Container {
    _initConfiguration(config) {
        super._initConfiguration(config);
        this._contentAreaId = `${this._id}-content-area`;
        this._cls = this._getConfigParam('cls', 'dropdown');
        this._onChange = this._getConfigParam('onChange', false);
        this._hasIcons = this._getConfigParam('hasIcons', true);
        this._forceDropdown = this._getConfigParam('forceDropdown', false);
        this._responsiveClass = this._getConfigParam('responsiveClass', false);
        this._headerClass = this._getConfigParam('headerClass', false);
        this._responsiveHeaderClass = this._getConfigParam('responsiveHeaderClass', false);
    }

    _initComponentElement() {
        super._initComponentElement();

        if (this._items.length && (this._forceDropdown || this._items.length > 1)) {
            this._updateComponentElement(
                '<button class="btn dropdown-toggle">' +
                    `<span>${this._getConfigParam('title')}</span>` +
                    ' <em class="caret"></em>' +
                '</button>' +
                `<ul id="${this._contentAreaId}" class="dropdown-menu dropdown-menu-flip${this._hasIcons ? ' dropdown-icon-menu' : ''}"></ul>`
            );
            this._componentElement.classList.add(this._cls);
        } else {
            this._componentElement = new Element('span', {
                id: this._id,
            });
            this._componentElement.innerHTML = this._getConfigParam('title');
        }
    }

    _addEvents() {
        super._addEvents();

        this._componentElement.addEventListener('click', event => {
            const header = document.querySelector(`.${this._headerClass}`);
            if (header && header.classList.contains('r-show-main-header-navbar')) {
                event.stopPropagation();
            }
        });

        const boxElement = this._componentElement.querySelector('.dropdown-toggle');
        if (boxElement) {
            DropdownManager.register(
                boxElement,
                () => this._componentElement.classList.contains('open'),
                () => {
                    this._componentElement.classList.add('open');
                },
                () => {
                    this._componentElement.classList.remove('open');
                }
            );
        }

        this._addResponsiveEvents();
    }

    _addResponsiveEvents() {
        if (!this._responsiveClass) {
            return;
        }

        const responsiveElement = document.querySelector(`.${this._responsiveClass}`);
        if (!responsiveElement) {
            return;
        }

        document.body.addEventListener('click', () => {
            this._hide();
        });

        responsiveElement.addEventListener('click', event => {
            this._show(responsiveElement);
            event.stopPropagation();
        });
    }

    _hide() {
        const header = document.querySelector(`.${this._headerClass}`);
        if (header) {
            header.className = this._headerClass;
        }
        document.querySelectorAll('.r-item-active').forEach(el => {
            el.classList.remove('r-item-active');
        });
    }

    _show(el) {
        let needShow = true;
        if (el.classList.contains('r-item-active')) {
            needShow = false;
        }

        this._hide();
        if (needShow) {
            const header = document.querySelector(`.${this._headerClass}`);
            if (header) {
                this._responsiveHeaderClass.split(' ').forEach(className => {
                    header.classList.add(className);
                });
            }
            el.classList.add('r-item-active');
        }
    }

    _renderItem(item) {
        if (!document.getElementById(this._contentAreaId)) {
            return;
        }
        const dropdownItem = typeof item === 'string' ? { value: item, title: item } : item;
        const li = dropdownItem.isHeader
            ? this.createHeader(dropdownItem)
            : this.createItem(dropdownItem);
        render(document.getElementById(this._contentAreaId), li);
    }

    createItem(item) {
        const attributes = {
            href: item.href ? item.href : '#',
        };
        if (item.target) {
            attributes.target = item.target;
        }

        if (item.dataType) {
            attributes['data-type'] = item.dataType;
        }

        if (item.disabled) {
            attributes.onclick = event => {
                event.preventDefault();
                event.stopPropagation();
            };
        } else if (this._onChange) {
            attributes.onclick = event => {
                this._onChange(event, item);
            };
        }

        if (item.description) {
            attributes.onrender = el => {
                Tooltip.init(el, { text: item.description });
            };
        }

        const valueItem = createElement('a', attributes, (item.iconClass ? `<i class="${item.iconClass}"></i>` : '') + escapeHtml(item.title));

        const liClasses = [];
        if (this._getConfigParam('title') === escapeHtml(item.title)) {
            liClasses.push('current');
        }
        if (item.disabled) {
            liClasses.push('disabled');
        }

        return createElement('li', { class: liClasses.join(' ') || null }, valueItem);
    }

    createHeader(item) {
        return createElement('li', { class: 'dropdown-header' }, item.title);
    }
}
