// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';

import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import RequireComponent from './RequireComponent';

const RouteRequire = ({ path, component, ...props }) => (
    <Route
        path={path}
        render={() => <RequireComponent component={component} />}
        {...props}
    />
);

RouteRequire.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
    ]).isRequired,
};

export default RouteRequire;
