// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { render } from 'react-dom';
import { createElement } from 'react';
import PropTypes from 'prop-types';
import { escapeHtml } from 'jsw';
import Status from './Status';
import PageContentHeader from './PageContentHeader';

const renderPageContentHeader = ({ pageTitle, ...props }) => {
    const mainTitle = escapeHtml(document.title.split(' - ').pop());

    const documentTitle = document.querySelector('title');
    if (documentTitle) {
        documentTitle.innerHTML = (
            pageTitle ? `${pageTitle.stripTags()} - ${mainTitle}` : mainTitle
        );
    }

    render((
        <PageContentHeader pageTitle={pageTitle} {...props} />
    ), document.querySelector('.page-content-header'));
};

const renderBottomAnchor = () => {
    const bottomAnchor = document.getElementById('bottomAnchor');

    if (bottomAnchor) {
        bottomAnchor.innerHTML = new Date().getTime();
    }
};

const PageContent = ({
    pageTitle,
    breadcrumbs,
    statusMessages,
    children,
}) => {
    if (pageTitle !== null && breadcrumbs) {
        renderPageContentHeader({ pageTitle, breadcrumbs });
    }
    renderBottomAnchor();

    return (
        <div>
            <Status messages={statusMessages} />
            {children}
        </div>
    );
};

PageContent.propTypes = {
    pageTitle: PropTypes.string,
    breadcrumbs: PropTypes.array,
    statusMessages: PropTypes.array.isRequired,
    children: PropTypes.element.isRequired,
};

PageContent.defaultProps = {
    pageTitle: null,
    breadcrumbs: null,
};

export default PageContent;
