// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { Form } from './form';

export class Login extends Form {
    _initComponentElement() {
        super._initComponentElement();

        const content = (
            '<div id="loginSection-username-form-row" class="form-row">' +
                `<div class="field-name"><label for="loginSection-username">${this.lmsg('loginLabel')}&nbsp;</label></div>` +
                '<div class="field-value">' +
                    '<input type="text"' +
                        ` name="${this._getConfigParam('loginInputName', '')}"` +
                        ` id="loginSection-username" value="${this._getConfigParam('loginNameValue', '')}"` +
                        ' class="input-text"' +
                    ' />' +
                '</div>' +
            '</div>' +

            '<div id="loginSection-password-form-row" class="form-row" >' +
                `<div class="field-name"><label for="loginSection-password">${this.lmsg('passwdLabel')}&nbsp;</label></div>` +
                '<div class="field-value">' +
                    `<input type="password" name="${this._getConfigParam('passwdInputName', '')}" id="loginSection-password" value="" class="input-text" />` +
                '</div>' +
            '</div>' +

            '<div class="form-row">' +
                `<div class="field-name"><label for="fid-locale">${this.lmsg('localeLabel')}&nbsp;</label></div>` +
                `<div class="field-value">${this._getConfigParam('localeSelect', '')}</div>` +
            '</div>'
        );

        this._componentElement.innerHTML = (
            '<div id="loginSection" class="form-box">' +
                `<div id="loginSection-content-area">${this._getConfigParam('warnings', '')}${content}</div>` +
            '</div>' +

            '<div class="btns-box">' +
                '<div class="box-area">' +
                    '<div class="form-row">' +

                        '<div class="field-name">' +
                            `<a href="/get_password.php">${this.lmsg('forgotPasswordLabel')}</a>` +
                        '</div>' +
                        '<div class="field-value">' +
                            `<button id="btn-send" class="btn" type="submit" value="" name="send">${this.lmsg('loginButtonLabel')}</button>` +

                            `<input type="image" src="${require('images/blank.gif')}" style="border: 0; height: 0; width: 0; position: absolute; left: -9999px;"/>` +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>'
        );

        this._componentElement.querySelector(`#fid-${this._getConfigParam('loginSelectName', '')}`).addEventListener('change', () => {
            this._componentElement._formSubmit();
        });
    }

    render() {
        super.render();

        this._componentElement.querySelector('#loginSection-username').focus();
    }

    _onSubmit() {
        if (this._componentElement.querySelector('#loginSection-username').value === ''
            || this._componentElement.querySelector('#loginSection-password').value === ''
        ) {
            // eslint-disable-next-line no-alert
            alert(this.lmsg('enterLoginAndPasswd'));
            this._componentElement.querySelector('#loginSection-username').focus();
            return;
        }

        super._onSubmit();
    }
}
