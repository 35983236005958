// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';

import { render } from 'react-dom';
import { api } from 'jsw';
import App from './App';

const Plesk = {
    data: {},

    run(config, data) {
        this.data = data;
        render(
            <App config={config} />,
            document.getElementById('main')
        );
    },

    getData(url) {
        if (this.data[url]) {
            return new Promise(resolve => {
                resolve(this.data[url]);
                delete this.data[url];
            });
        }

        return api.get(url);
    },
};

export default Plesk;
